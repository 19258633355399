export const haveSelectedGas = (station, gas) => {
  if (!station.prices || station.prices.length === 0 ) {
    return false;
  }

  for (let i = 0 ; i < station.prices.length ; i +=1 ){
    if (station.prices[i].gasType === gas ) {
      return true;
    }
  }
  return false; 
}

export const lowerCaseString = (string) => {
  if ( !string){
    return '';
  }
  return string.toLowerCase();
}

export const capitalizeFirstLetter = (string) => {
  if ( !string){
    return '';
  }
  return string.charAt(0).toUpperCase() + lowerCaseString(string.slice(1));
}