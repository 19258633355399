import React from 'react';
import { Form, Row, Col } from "react-bootstrap";
import PropTypes from 'prop-types';
import {gasTypes} from "../config";

const GasTypes = (props) => {
  const {
    selectGasType,
    selectedGasType,
  } = props;

  return (
    <Row style={{ width: "100%" }}>
      <Col>
        <Form.Control as="select" value={selectedGasType} onChange={selectGasType}>
          {gasTypes.map(gasType => (<option key={gasType.type} value={gasType.type}>{gasType.name}</option>))}
        </Form.Control>
      </Col>
    </Row>
  );
}

GasTypes.propTypes = {
  selectedGasType: PropTypes.string.isRequired,
  selectGasType: PropTypes.func.isRequired,
};

export default GasTypes;