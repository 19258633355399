import React from 'react';
import { ToastProvider } from 'react-toast-notifications';
import Header from './Components/Header';
import Footer from './Components/Footer';
import GasStation from "./Components/GasStation";
import About from "./Components/About";
import Map from "./Components/Map";

import 'mapbox-gl/dist/mapbox-gl.css';

import './App.css';

class Application extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showModal: false,
      showAboutModal: false,
      selectedGasStation: {},
      selectedGasType: 'E85',
      needUpdateUserLocation: false
    };
  }

  /**
   * Méthode permettant de sélectionner le type de carburant à afficher
   * @param {Object} e
   */
  selectGasType = (e) => {
    const {
      value,
    } = e.target;
    this.setState(prevState => ({
      ...prevState,
      selectedGasType: value
    }));
  }

  /**
   * Méthode permettant de fermer la modale contenant les détails de la station
   * @param {Boolean} goToWaze
   */
  hideModal = (goToWaze) => {
    const {
      selectedGasStation,
    } = this.state;
    if (goToWaze) {
      const {
        coordinates,
      } = selectedGasStation.location;

      window.open(`https://www.waze.com/livemap/directions?navigate=yes&latlng=${coordinates[1]}%2C${coordinates[0]}&zoom=17`);
    }
    this.setState(prevState => ({
      ...prevState,
      selectedGasStation: {},
      showModal: false
    }));
  }

  /**
   * Méthode permettant de sélectionner et d'afficher le détails d'une station
   * @param {Object} selectedGasStation 
   */
  showGasStation = (selectedGasStation) => {
    this.setState(prevState => ({
      ...prevState,
      showModal: true,
      selectedGasStation
    }));
  }

  /**
   * Méthode permettant de demander à l'app de géoloc ou non l'utilisateur
   */
  setNeedUpdateUserLocation = (needUpdateUserLocation) => {
    this.setState({
      needUpdateUserLocation
    })
  }

  toggleAboutModal = () => {
    this.setState(prevState => ({
      showAboutModal: !prevState.showAboutModal
    }));
  }

  /**
   * Méthode gérant le rendu de la vue
   */
  render() {
    const {
      selectedGasType,
      showModal,
      showAboutModal,
      selectedGasStation,
      userLocation,
      needUpdateUserLocation,
    } = this.state;

    return (
      <ToastProvider>
        <Header toggleAboutModal={this.toggleAboutModal} />
        <GasStation
          showModal={showModal}
          hideModal={this.hideModal}
          station={selectedGasStation}
          gasType={selectedGasType}
        />
        <About
          showModal={showAboutModal}
          toggleAboutModal={this.toggleAboutModal}
        />
        <Map selectedGasType={selectedGasType} userLocation={userLocation} needUpdateUserLocation={needUpdateUserLocation} setNeedUpdateUserLocation={this.setNeedUpdateUserLocation} showGasStation={this.showGasStation} />
        <Footer selectedGasType={selectedGasType} isLocating={needUpdateUserLocation} selectGasType={this.selectGasType} setNeedUpdateUserLocation={this.setNeedUpdateUserLocation} />
      </ToastProvider>
    );
  }
}

export default Application;
