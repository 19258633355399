export const gasTypes = [
  {
    name: "Superéthanol E85",
    type: 'E85',
  },
  {
    name: "Sans plomb 95 E10",
    type: "E10"
  },
  {
    name: "Sans plomb 95",
    type: "SP95"
  },
  {
    name: "Sans plomb 98",
    type: "SP98"
  },
  {
    name: "Gazole",
    type: "Gazole"
  },
  {
    name: "GPL",
    type: "GPLc"
  }
];
export const mapboxToken = process.env.REACT_APP_MAPBOX_TOKEN;
export const baseApiUrl = process.env.REACT_APP_API_URL;
export const radius = Number(process.env.REACT_APP_MAP_RADIUS);