import React from 'react';
import { Navbar, Nav } from "react-bootstrap";
import PropTypes from 'prop-types';

const Header = (props) => {
  const {
    toggleAboutModal,
  } = props;

  return (
    <Navbar expand="lg" bg="light" variant="light" fixed="top">
      <Navbar.Brand href="https://www.darkou.fr">
        <img
          src="/logo.png"
          width="68"
          height="30"
          className="d-inline-block align-top"
          alt="DarKou.fr"
        />
      </Navbar.Brand>
      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <Navbar.Collapse id="basic-navbar-nav">
        <Nav className="mr-auto">
          <Nav.Link onClick={toggleAboutModal}>A propros</Nav.Link>
          <Nav.Link href="https://www.darkou.fr/contact/">Contact</Nav.Link>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
};

Header.propTypes = {
  toggleAboutModal: PropTypes.func.isRequired,
};

export default Header;