import React from 'react';
import { Modal, Button, ListGroup } from "react-bootstrap";
import PropTypes from 'prop-types';
import { FaWaze } from "react-icons/fa";
import { GiPositionMarker } from "react-icons/gi";
import { gasTypes } from "../config";

import { capitalizeFirstLetter, lowerCaseString } from "../helpers";

class GasStation extends React.Component {
  /**
   * Méthode permettant de convertir un type de carburant en un nom lisible
   * @param {string} gasType
   * @return {mixed}
   */
  renderGasType = (gasType) => {
    for( let i = 0 ; i < gasTypes.length ; i +=1 ) {
      if ( gasTypes[i].type === gasType) {
        return gasTypes[i].name;
      }
    }

    return (null);
  }

  /**
   * Méthode permettant de générer la liste des prix
   * @return {string}
   */
  renderPrices = () => {
    const {
      gasType,
      station,
    } = this.props;

    return (
      <ListGroup variant="flush">
        {station.prices ? station.prices.map(price => {
          return (
            <ListGroup.Item key={price._id} className={gasType === price.gasType ? "selected-gasType" : ""}>
              {`${this.renderGasType(price.gasType)} : ${price.price.toString().replace('.', ',')} € `}
            </ListGroup.Item>
          );
        }) : (null)}
      </ListGroup>
    )
  }

  /**
   * Méthode permettant de formater le titre de la modale en fonction des infos reçues
   * @return {string}
   */
  renderFormatTitle = () => {
    const {
      station,
    } = this.props;

    if ( station.name ) {
      return station.name;
    }
    return `${lowerCaseString(station.address)} - ${capitalizeFirstLetter(station.city)}`;
  }

  renderAddress = () => {
    const {
      station,
    } = this.props;

    return (
      <p>
        <GiPositionMarker /> 
        {lowerCaseString(station.address)}
        {' - '}
        {capitalizeFirstLetter(station.city)}
      </p>
    );
  }

  render () {
    const {
      showModal,
      hideModal,
    } = this.props;
  
    return (
      <Modal
        size="xl"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={showModal}
        onHide={hideModal}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {this.renderFormatTitle()}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {this.renderAddress()}
          {this.renderPrices()}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={() => hideModal(true)}>
            <FaWaze size="2em" />
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}

GasStation.defaultProps = {
  station: {
    address: null,
    city: null,
    prices: []
  }
};

GasStation.propTypes = {
  gasType: PropTypes.string.isRequired,
  showModal: PropTypes.bool.isRequired,
  hideModal: PropTypes.func.isRequired,
  station: PropTypes.shape({
    name: PropTypes.string,
    address: PropTypes.string,
    city: PropTypes.string,
    prices: PropTypes.array
  }),
};

export default GasStation;