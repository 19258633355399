import React from 'react';
import { Modal } from "react-bootstrap";
import PropTypes from 'prop-types';

const About = (props) => {
  const {
    toggleAboutModal,
    showModal
  } = props;

  return (
    <Modal
      size="xl"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      show={showModal}
      onHide={() => {toggleAboutModal(false)}}
    >
      <Modal.Header closeButton>
        <Modal.Title>À propos</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <strong>Prix carburant</strong>
        {' '}
        est une application ayant pour objectif d&apos;afficher facilement sur smartphone l&apos;ensemble des stations services à proximité.
        <br />
        Ce service est entièrement gratuit et libre.
        <br />
        La liste des stations est issue des 
        {' '}
        <a href="https://www.prix-carburants.gouv.fr/rubrique/opendata/" rel="noopener noreferrer" target="_blank">Open Data</a>
        {' '}
        fournies par le gouvernement.
        <br />
        L&apos;application est développée et maintenue par 
        {' '}
        <a href="https://darkou.fr" rel="noopener noreferrer" target="_blank">Damien Broqua</a>
        .
        <br />
        Le code source de l&apos;application est librement téléchargeable sur 
        {' '}
        <a href="https://framagit.org/dbroqua/prix-carburants" rel="noopener noreferrer" target="_blank">framagit</a>
        .
      </Modal.Body>
    </Modal>
  );

};

About.propTypes = {
  toggleAboutModal: PropTypes.func.isRequired,
  showModal: PropTypes.bool.isRequired
};

export default About;