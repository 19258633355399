import React from 'react';
import {
  Navbar,
  Button,
  Spinner 
} from "react-bootstrap";
import PropTypes from 'prop-types';
import { MdGpsFixed } from "react-icons/md";

import GasTypes from "./GasTypes";

const Footer = (props) => {
  const {
    selectGasType,
    selectedGasType,
    setNeedUpdateUserLocation,
    isLocating,
  } = props;

  return (
    <Navbar bg="light" variant="light" fixed="bottom">
      <GasTypes selectedGasType={selectedGasType} selectGasType={selectGasType} />
      <div style={{width: "36px", display: "flex", justifyContent: "center", paddingLeft: "1rem"}}>
        {isLocating ? (
          <Spinner animation="border" size="sm" role="status">
            <span className="sr-only">Chargement...</span>
          </Spinner>
      ) : ( 
        <Button
          variant="link"
          onClick={() => setNeedUpdateUserLocation(true)}
          onFocus={() => { }}
          onBlur={() => { }}
        >
          <MdGpsFixed />
        </Button>
      )}
      </div>
    </Navbar>
  );
};

Footer.propTypes = {
  isLocating: PropTypes.bool.isRequired,
  selectedGasType: PropTypes.string.isRequired,
  selectGasType: PropTypes.func.isRequired,
  setNeedUpdateUserLocation: PropTypes.func.isRequired,
};

export default Footer;